x
<template>
  <div>
    <h4>PRODUCTOS Y SERVICIOS</h4>
    <div class="row">
      <div class="col-sm-4 col-lg-3">
        <TypeRadioControl
          class="mb-2"
          :value="type_id"
          @input="typeInputHandler"
        ></TypeRadioControl>
        <div class="form-group">
          <label for="">Area</label>
          <SelectArea :value="area_id" @input="selectAreaHandler"></SelectArea>
        </div>
      </div>
      <div class="col">
        <app-table-registers ref="tr" :getList="getList">
          <template slot="tl">
            <div class="col form-inline">
              <button
                class="btn btn-primary mr-2"
                @click="
                  $refs.elFormProduct.reset();
                  $refs.elModalFormProduct.show();
                "
              >
                <i class="fa fa-plus"></i> Nuevo
              </button>
              <!-- <SelectType -->
              <!--   class="d-inline w-auto" -->
              <!--   :all="true" -->
              <!--   v-model="type_id" -->
              <!-- ></SelectType> -->
              <!-- <div class="form-inline ml-2">
            <SelectCategorie
              ref="selCat"
              v-model="s_categorie_id"
            ></SelectCategorie>
          </div> -->
            </div>
          </template>
          <template slot="table">
            <thead>
              <tr>
                <th>ID</th>
                <th></th>
                <th>NOMBRE</th>
                <th>CODIGO</th>
                <!-- <th>FUENTE DE INGRESO</th> -->
                <th>TIPO</th>
                <th class="text-right">PRECIO UNITARIO</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <app-tr-loading v-show="loading" colspan="7"></app-tr-loading>
              <tr v-for="l in list" :key="l.id">
                <td>{{ l.id }}</td>
                <td>
                  <!-- <app-img-viewer -->
                  <!--   style="width: 70px;" -->
                  <!--   v-if="l.file_id" -->
                  <!--   :src="urlToImages + l.file_id" -->
                  <!-- ></app-img-viewer> -->
                  <img :src="l.file_id | imageUrl" alt="" class="app-img-sm" />
                </td>
                <td>
                  <div>{{ l.name }}</div>
                  <span class="badge badge-light" v-show="l.brand_name"
                    >{{ l.brand_name }}
                  </span>
                </td>
                <td>{{ l.code }}</td>
                <!-- <td>{{ l.source_name }}</td> -->
                <td>{{ types[l.type_id] }}</td>
                <td class="text-right">
                  <app-span-money
                    :quantity="l.unit_price"
                    :moneyCode="l.money_code"
                  ></app-span-money>
                </td>
                <td>
                  <div class="btn-group">
                    <button
                      class="btn btn-light btn-sm"
                      @click="
                        $refs.elFormProduct.loadReg(l);
                        $refs.elModalFormProduct.show();
                      "
                    >
                      <i class="fa fa-edit"></i>
                    </button>
                    <button
                      class="btn btn-light btn-sm"
                      @click="clickGroupHandler(l.id)"
                    >
                      <i class="fas fa-clone"></i>
                    </button>
                    <button
                      class="btn btn-light btn-sm"
                      @click="
                        $refs.myn.val = l;
                        $refs.myn.show();
                      "
                    >
                      <i class="fa fa-trash"></i>
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </app-table-registers>
      </div>
    </div>

    <app-modal-basic ref="elModalFormProduct" :xl="true" :lg="true">
      <h4>FORMULARIO DE PRODUCTO</h4>
      <FormProduct
        ref="elFormProduct"
        @submitted="afterCreateHandler"
      ></FormProduct>
    </app-modal-basic>

    <app-modal-yn
      ref="myn"
      @no="$refs.myn.hide()"
      @yes="
        deleteItem($refs.myn.val.id);
        $refs.myn.hide();
      "
    >
      <p v-if="$refs.myn && $refs.myn.val">
        Seguro de eliminar el producto
        <strong>{{ $refs.myn.val.name }}</strong>
        ?
      </p>
    </app-modal-yn>

    <app-modal-basic ref="elModalGroup">
      <Groups ref="elGroups"></Groups>
    </app-modal-basic>
  </div>
</template>

<script>
import FormProduct from "./Form.vue";
// import SelectType from "./SelectType.vue";
import { StoreService } from "../service";
import Groups from "./Groups.vue";
//import RadioType from "./RadioType.vue";
import routeMixin from "../../utils/routeMixin.js";
import TypeRadioControl from "./TypeRadioControl.vue";
import SelectArea from "../../admin-module/areas/Select.vue";

export default {
  mixins: [routeMixin],

  components: {
    TypeRadioControl,
    Groups,
    // SelectCategorie,
    FormProduct,
    SelectArea
    // SelectType,
    //RadioType
  },
  props: {
    categorie_id: {
      default: null
    }
    // area_id: {
    //   default: null
    // }
  },
  created() {
    try {
      this.type_id = this.$route.query.type_id || null;
      this.area_id = this.$store.state.user.area_id || null;
    } catch (e) {
      //
    }
  },
  mounted() {},
  data: () => ({
    s_categorie_id: null,
    list: [],
    type_id: null,
    types: { 1: "BIENES", 2: "SERVICIOS" },
    loading: false,
    brands: [],
    area_id: null
  }),
  watch: {
    //
  },
  computed: {
    urlToImages() {
      return process.env.VUE_APP_API_URL + "files/";
    }
  },
  methods: {
    selectAreaHandler(areaId) {
      this.area_id = areaId;
      this.reload();
    },
    typeInputHandler(typeId) {
      this.type_id = typeId;
      this.setQueryParam("type_id", typeId);
      this.reload();
    },
    typeIdChangeHandler() {
      this.reload();
      this.addQueryParam("type_id", this.type_id);
    },
    reload() {
      this.$refs.tr.gl();
    },
    clickGroupHandler(productId) {
      this.$refs.elModalGroup.show();
      this.$refs.elGroups.beginFromProductId(productId);
    },
    afterCreateHandler() {
      this.$refs.elModalFormProduct.hide();
      this.$refs.tr.gl();
    },
    deleteItem(reg_id) {
      StoreService.deleteProduct(reg_id).then(() => this.$refs.tr.gl());
    },
    async getList({ page, search }) {
      this.loading = true;
      this.list = [];
      let responsePaginated = await StoreService.getProducts({
        page,
        search,
        type_id: this.type_id,
        categorie_id: this.categorie_id,
        area_id: this.area_id
      });
      this.list = responsePaginated.data;
      this.brands = responsePaginated.brands;
      this.loading = false;
      return responsePaginated;
    }
  }
};
</script>

<style scoped>
.app-img-sm {
  width: 5rem;
}
</style>
